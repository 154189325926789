import * as React from 'react'
import Nav from "../components/nav"
import Footer from "../components/footer"
import Hero from "../components/hero"
import {Helmet} from "react-helmet"
import OpenGraphLogo from "../images/open-graph-logo.png"

function VirtualAppointments() {
    return (
        <div>
            <Helmet>
    <title>Virtual Physical Therapy & Training | Kinetic Impact</title>
    <meta property="og:title" content="Virtual Physical Therapy & Training | Kinetic Impact" />
    <meta property="og:image" content={OpenGraphLogo} />
    <meta property="og:description" content="Experience expert physical therapy from home with our virtual consultations, remote movement assessments, and guided online exercises. Professional care, anywhere you are." />
    <meta name="description" content="Transform your recovery with virtual physical therapy sessions. Get expert movement assessments, guided exercises, and professional follow-up care from the comfort of your home." />
    <meta name="keywords" content="virtual physiotherapy, remote physical therapy, online movement assessment, virtual consultations, guided online exercises, remote physio follow-ups, telehealth physical therapy" />
</Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/virtual-appointments"} 
                  header={"Expert Physical Therapy Care From the Comfort of Your Home"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Experience professional care without leaving your space. Our virtual consultations bring expert physical therapy directly to you through secure video appointments.
                    </p>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Whether you're dealing with ongoing pain, recovering from an injury, or looking to improve your movement patterns, our virtual care provides the support and guidance you need to achieve your goals.
                    </p>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto w-full"}>
                        Ready to start your recovery journey from home? Our team of experts is here to help you move better and feel stronger.
                    </p>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/virtual-appointments"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Book Your Virtual Session
                        </button>
                    </a>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>What We Treat Virtually</h2>
                    
                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Back & Neck Pain Management</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Our virtual sessions are perfect for addressing chronic back pain, neck tension, and posture-related issues. We'll guide you through effective exercises and movement patterns to reduce pain and prevent future problems.
                        </p>
                        
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Sports & Exercise Injury Recovery</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Whether you're dealing with a running injury, workout-related pain, or general athletic concerns, our virtual assessments and guided exercises can help you recover and return to your activities safely.
                        </p>
                        
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Post-Surgery Rehabilitation</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Continue your post-surgical recovery with expert guidance from home. We'll help you progress through your rehabilitation safely and effectively, ensuring proper form and technique through video consultation.
                        </p>
                        
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Movement Assessment & Management Plan</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Our virtual movement assessments can identify patterns that may be contributing to your pain or limiting your performance. We'll create a personalized plan to improve your movement quality and prevent future injuries.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/virtual-appointments"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Let's Work Together!
                        </button>
                    </a>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Our Virtual Care Process</h2>

                    <div className={"flex flex-col justify-center"}>
                    <div className='pb-4 pt-4 flex flex-row justify-center items-center gap-5'>
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-9 h-9 fill-accent shrink-0'><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
   <h3 className={"text-center text-3xl"}>Free Discovery Call</h3>
</div>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Before beginning treatment, we offer a free consultation to understand your needs and ensure virtual care is the right fit for you. We'll discuss your concerns, goals, and how our virtual services can help you achieve them.
                        </p>

                        <div className='pb-4 pt-4 flex flex-row justify-center items-center gap-5'>
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-10 h-10 fill-accent shrink-0'><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
   <h3 className={"text-center text-3xl"}>Initial Assessment</h3>
</div>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            During your first session, we'll conduct a thorough movement assessment through video consultation. We'll analyze your movement patterns, identify any limitations, and develop a personalized treatment plan.
                        </p>

                        <div className='pb-4 pt-4 flex flex-row justify-center items-center gap-5'>
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='w-10 h-10 fill-accent shrink-0'><path d="M160 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM126.5 199.3c-1 .4-1.9 .8-2.9 1.2l-8 3.5c-16.4 7.3-29 21.2-34.7 38.2l-2.6 7.8c-5.6 16.8-23.7 25.8-40.5 20.2s-25.8-23.7-20.2-40.5l2.6-7.8c11.4-34.1 36.6-61.9 69.4-76.5l8-3.5c20.8-9.2 43.3-14 66.1-14c44.6 0 84.8 26.8 101.9 67.9L281 232.7l21.4 10.7c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3L247 287.3c-10.3-5.2-18.4-13.8-22.8-24.5l-9.6-23-19.3 65.5 49.5 54c5.4 5.9 9.2 13 11.2 20.8l23 92.1c4.3 17.1-6.1 34.5-23.3 38.8s-34.5-6.1-38.8-23.3l-22-88.1-70.7-77.1c-14.8-16.1-20.3-38.6-14.7-59.7l16.9-63.5zM68.7 398l25-62.4c2.1 3 4.5 5.8 7 8.6l40.7 44.4-14.5 36.2c-2.4 6-6 11.5-10.6 16.1L54.6 502.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L68.7 398z"/></svg>
   <h3 className={"text-center text-3xl"}>Guided Treatment Sessions</h3>
</div>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Each virtual session includes real-time guidance through exercises and movements, ensuring proper form and technique. We'll progress your program as you improve and adapt it based on your response to treatment.
                        </p>

                        <div className='pb-4 pt-4 flex flex-row justify-center items-center gap-5'>
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='w-10 h-10 fill-accent shrink-0'><path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>
   <h3 className={"text-center text-3xl"}>Ongoing Support</h3>
</div>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Between sessions, you'll have access to your physical therapist for questions and support. We'll monitor your progress and adjust your program as needed to ensure you're meeting your goals.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/virtual-appointments"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10 mb-4"}>
                            Start Your Virtual Care Journey
                        </button>
                    </a>
                </div>
            </Hero>
            <Footer/>
        </div>
    )
}

export default VirtualAppointments